<template>
  <v-container>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Facturación Electrónica</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row class="mt-4">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-card-subtitle>
                <v-row>
                  <v-col cols="12" md="1">
                    <v-btn class="ma-2" outlined x-large fab color="primary">
                      <v-icon color="primary">{{ "mdi-file-document" }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="11">
                    <br />
                    <p class="mt-3">
                      Lleva tu negocio al siguiente nivel con nuestros planes de
                      facturación electrónica
                    </p>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-card flat class="mt-10" max-width="100%">
          <v-card class="mx-auto">
            <v-toolbar color="cyan darken-1" dark>
              <v-toolbar-title> Beneficios </v-toolbar-title>
            </v-toolbar>
            <div class="py-5 px-3">
              <v-list-item
                class="my-2"
                v-for="(benefit, i) in benefits"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ "mdi-check-decagram" }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ benefit.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    benefit.subtitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-card>
        </v-card>
        <div class="mt-15 mb-15">
          <p class="text-h2 font-weight-bold text-center">
            Planes de Facturación.
          </p>
          <p class="text-center text-h6">
            Conoce nuestros planes de facturación pensados para cada tipo de
            empresa.
          </p>
        </div>
        <v-row>
          <v-col
            cols="12"
            v-for="(plan, i) in plans"
            :key="i"
            md="4"
            class="pt-10"
          >
           
            <v-card
              v-if="plan.price > 0"
              class="mx-auto"
              elevation="10"
              max-width="95%"
            >
              <v-card-title
                v-if="(i+3)%3 == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #0e73b1"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="(i+2)%3 == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #e9501c"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="(i +1)%3== 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #179db0"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <br />
                <p
                  v-if="(i+3)%3 == 0 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #0e73b1"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="(i+2)%3 == 0&& plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #e9501c"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="(i+1)%3 == 0 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #179db0"
                >
                  {{ plan.price }} CLP
                </p>
                <p class="text-center" v-if="plan.price > 0">
                  <strong> IVA incluido</strong>
                </p>
              </v-card-text>
              <v-divider v-if="plan.price > 0"></v-divider>
              <v-card-subtitle v-if="plan.price > 0">{{
                plan.description
              }}</v-card-subtitle>
              <v-divider v-if="plan.price > 0"></v-divider>
              <v-card-actions class="justify-center pt-3 pb-3">
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="(i+3)%3 == 0 && plan.price > 0"
                  class="white--text"
                  color="#0e73b1"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="(i+2)%3 == 0 && plan.price > 0"
                  class="white--text"
                  color="#e9501c"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="(i+1)%3 == 0 && plan.price > 0"
                  class="white--text"
                  color="#179db0"
                >
                  <v-icon right dark> mdi-cart </v-icon> Adquirir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            
            <v-card flat class="mt-10" max-width="100%">
              <v-card class="mx-auto">
                <v-toolbar color="cyan darken-1" dark>
                  <v-toolbar-title>Todos los planes incluyen</v-toolbar-title>
                </v-toolbar>
                <v-row class="mt-5 mb-5 ml-5">
                  <v-col cols="12" md="4">
                    <v-img
                      src="../../../src/assets/pay2-pic1.jpg"
                      class="mx-2"
                      max-width="400"
                      contain
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-list class="mt-10">
                      <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item
                          v-for="(plan, i) in plans_landing"
                          :key="i"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">{{ plan.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="plan.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <div class="ml-10 text-h6 font-weight-bold">
                      <p style="color: #213b7c">Opcional</p>
                    </div>
                    <p class="ml-4">
                      <v-icon color="primary">mdi-check-decagram </v-icon>
                      Licencia para aplicación smartphone o POS dedicado: $7.000
                      mensual por equipo.
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import Config from "../../config";
export default {
  data: () => ({
    plans: [],
    plans_landing: [
      {
        text: "Firma electrónica gratis por un año.",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "Usuarios ilimitados.",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "API de integración para sistemas propios. (páginas web, ERP, etc.).",
        icon: "mdi-check-decagram",
        color: "primary",
      },
    ],
    benefits: [
      {
        title: "Automatiza procesos.",
        subtitle: "Integración con sistemas propios o páginas web vía API.",
      },
      {
        title: "Emisión masiva.",
        subtitle:
          "La facturación de fin de mes nunca había sido tan fácil, utiliza la facturación masiva integrada a Google Spreadsheets.",
      },
      {
        title: "Alto flujo de documentos.",
        subtitle:
          "Permite un alto flujo de emisión de documentos por segundo, ideal para operaciones en simultáneo con múltiples usuarios.",
      },
      {
        title: "Usuarios ilimitados.",
        subtitle: "Asigna diferentes roles y permisos a tus colaboradores.",
      },
    ],
    planss: [
      {
        text: "Firma electrónica gratis (al contratar por un año o más, se puede pagar mensual o el año completo).",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "Usuarios Ilimitados.",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "API de integración para sistemas propietarios. (ERP, sistemas de caja, etc).",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "Todos los documentos tributarios electrónicos disponibles.",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "Migración desde el SII u otras plataformas.",
        icon: "mdi-check-decagram",
        color: "primary",
      },
      {
        text: "Habilitación y certificación incluida. (no incluye integración de API).",
        icon: "mdi-check-decagram",
        color: "primary",
      },
    ],
  }),
  computed: {},
  mounted() {
    this.getPlans();
  },
  watch: {},
  methods: {
    async getPlans() {
      let request = {
        id_service: Config.idFacturacionElectronica,
      };
      axios
        .post("/plan-by-service", request)
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },
    contratar(id_plan) {
      this.$router.push({
        name: "create-contract-by-customer",
        params: {
          id_plan: id_plan,
          id_servicio: Config.idBoletaElectronica,
        },
      });
    },
  },
};
</script>